import { render, staticRenderFns } from "./toCenter.vue?vue&type=template&id=32aa98e9&scoped=true&"
import script from "./toCenter.vue?vue&type=script&lang=js&"
export * from "./toCenter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32aa98e9",
  null
  
)

export default component.exports